<template>
  <CreateUpdateTemplate
    :routePreventDialog="routePreventDialog"
    :hasChanges="hasChanges"
    :customClass="'create-quotation create-form'"
    v-if="
      getPermission('quotation:create') || getPermission('quotation:update')
    "
  >
    <template v-slot:header-title>
      <div class="d-flex justify-space-between">
        <h1 class="form-title text-truncate d-flex align-baseline">
          Proforma for
          <div
            v-if="vCustomerId"
            class="ml-3 form-title-link cursor-pointer text-h5"
          >
            {{ vCustomer.company_name }}
          </div>
          <template v-else>
            <div class="ml-3 form-title-link cursor-pointer text-h5">
              Select a Company
              <v-icon large color="cyan">mdi-account-check-outline</v-icon>
            </div>
            <div class="mx-3">OR</div>
            <div
              class="form-title-link cursor-pointer cyan--text text--darken-4 text-h5"
              v-on:click="createCustomerDialog()"
            >
              Create a new Company
              <v-icon large color="cyan darken-4"
                >mdi-plus-circle-outline</v-icon
              >
            </div>
          </template>
        </h1>

        <v-spacer></v-spacer>
        <v-chip
          v-if="updatedQuotationData.tag_type"
          label
          style="font-size: 19px !important"
          class="text-capitalize mr-4"
          text-color="white"
          :color="updatedQuotationData.tag_type == 'overseas' ? 'blue' : 'red'"
        >
          {{ updatedQuotationData.tag_type }}
        </v-chip>
        <v-chip
          label
          color="red"
          outlined
          class="text-white p-3 mr-4"
          style="font-size: 21px !important; font-weight: 700"
        >
          <template v-if="$route?.query?.type == 'initial'">
            Initial Proforma
          </template>
          <template v-else> Deliverable Proforma </template>
        </v-chip>

        <v-chip
          label
          color="chip-custom-blue"
          outlined
          class="text-white p-3 mr-4"
          style="font-size: 21px !important; font-weight: 700"
        >
          <template>
            {{ dbQuotation.barcode }}
          </template>
        </v-chip>
      </div>
    </template>
    <template v-slot:header-action>
      <div>
        <v-btn
          :disabled="formLoading"
          v-on:click="goBack()"
          class="custom-bold-button custom-grey-border"
          depressed
          >Cancel</v-btn
        >
        <v-btn
          class="custom-bold-button ml-4"
          depressed
          :disabled="
            !formValid ||
            formLoading ||
            ((customError || customErrorOcc) &&
              quoteInvoiceParameters.invoice_type == 'recurring')
              ? true
              : false
          "
          :loading="formLoading"
          v-on:click="validateJob()"
          color="cyan white--text"
          >Save</v-btn
        >
      </div>
    </template>
    <template v-slot:body>
      <perfect-scrollbar
        :options="{ suppressScrollX: true }"
        class="scroll custom-box-top-inner-shadow product-create-height"
        style="position: relative"
      >
        <div class="bg-white px-2">
          <v-form
            ref="createQuotationForm"
            v-model.trim="formValid"
            lazy-validation
            v-on:submit.stop.prevent="validateJob()"
          >
            <v-row>
              <!--  <v-col md="12">
            <div class="form-action">
              <div class="form-title">
                Quotation for {{ reCustomerId }}
                <span
                  class="form-title-link cursor-pointer"
                  v-on:click="openDialog('customer')"
                >
                  <template v-if="reCustomerId && reCustomerId > 0">{{
                    vCustomer.display_name
                  }}</template>
                  <template v-else>
                    Select a Customer
                    <v-icon large color="cyan">mdi-plus-circle-outline</v-icon>
                  </template>
                </span>
              </div>
              <div>
                <v-chip
                  label
                  color="chip-custom-blue"
                  outlined
                  class="text-white mr-2"
                  style="font-size: 21px !important; font-weight: 700"
                  >{{ dbQuotation.barcode }}</v-chip
                >
                <v-btn
                  :disabled="formLoading"
                  v-on:click="goBack()"
                  class="custom-bold-button custom-grey-border"
                  depressed
                  >Cancel</v-btn
                >
                <v-btn
                  class="custom-bold-button ml-4"
                  depressed
                  :disabled="!formValid || formLoading"
                  :loading="formLoading"
                  v-on:click="validateJob()"
                  color="cyan white--text"
                  >Save</v-btn
                >
              </div>
            </div>
          </v-col> -->
              <v-col md="7">
                <label for="quotation-title" class="required mt-2"
                  >Proforma Title</label
                >
                <TextField
                  id="quotation-title"
                  dense
                  filled
                  placeholder="Title"
                  solo
                  flat
                  counter="50"
                  v-model="dbQuotation.title"
                  color="cyan"
                  :maxlength="250"
                  :rules="[validateRules.required(dbQuotation.title, 'Title')]"
                />
                <div class="mt-2">
                  <TextAreaField
                    auto-grow
                    dense
                    filled
                    color="cyan"
                    v-model="dbQuotation.description"
                    placeholder="Description"
                    solo
                    flat
                    row-height="25"
                    counter="250"
                  />
                </div>
                <v-row>
                  <v-col md="6">
                    <v-layout class="align-center">
                      <v-flex md3
                        ><label class="my-0"
                          ><b>Billing Location</b></label
                        ></v-flex
                      >
                      <v-flex md9 class="position-relative">
                        <v-icon
                          v-if="false"
                          v-on:click="openDialog('billing')"
                          small
                          class="py-0 my-0"
                          color="cyan"
                          style="position: absolute; top: -8px"
                          >mdi-pencil</v-icon
                        >
                      </v-flex>
                    </v-layout>
                    <template v-if="vBilling?.id">
                      <span v-if="vBilling && vBilling.property_address">
                        <!--  <v-icon small>mdi-map-marker</v-icon> --></span
                      >
                      <template>
                        <div
                          class="custom-flex-d"
                          v-if="vBilling.property_name"
                        >
                          <b>
                            <v-icon
                              style="
                                font-size: 18px;
                                margin-top: -3px !important;
                              "
                              color="#000"
                              class="cmt-2"
                            >
                              mdi-home-map-marker
                            </v-icon>
                          </b>
                          <span>{{ vBilling.property_name }}<br /></span>
                        </div>
                        <div v-else>
                          <em class="text-muted">
                            <v-icon
                              style="
                                font-size: 18px;
                                margin-top: -3px !important;
                              "
                              color="#000"
                              class="cmt-2"
                            >
                              mdi-home-map-marker
                            </v-icon>
                            no location Name</em
                          >
                        </div>
                      </template>

                      <template>
                        <div class="custom-flex-d">
                          <b>
                            <v-icon
                              style="
                                font-size: 18px;
                                margin-top: -3px !important;
                              "
                              color="#000"
                              class="cmt-2"
                            >
                              mdi-map-marker
                            </v-icon>
                          </b>
                          <span>
                            <template v-if="vBilling.unit_no"
                              >{{ vBilling.unit_no }},</template
                            >
                            <template v-if="vBilling.street_1"
                              >{{ vBilling.street_1 }},</template
                            >
                            {{ vBilling.street_2 }}
                            <br />
                            {{ vBilling.country }}
                            <template
                              v-if="
                                vBilling.zip_code &&
                                vBilling.zip_code != '000000'
                              "
                            >
                              {{ vBilling.zip_code }}
                            </template>
                          </span>
                        </div>
                      </template>
                    </template>
                    <template v-else-if="vCustomer?.id">
                      <p class="mb-0 red--text">
                        Please select billing location
                      </p>
                    </template>
                    <template v-else>
                      <p class="mb-0 red--text">Please select customer</p>
                    </template>
                  </v-col>
                  <v-col md="6">
                    <v-layout class="mt-3 align-center">
                      <v-flex md3
                        ><label class="my-0">
                          <b>Contact Details</b></label
                        ></v-flex
                      >
                      <v-flex md9 class="position-relative">
                        <v-icon
                          small
                          v-if="false"
                          v-on:click="openDialog('billing-contact')"
                          class="py-0 my-0"
                          color="cyan"
                          style="position: absolute; top: -8px"
                          >mdi-pencil</v-icon
                        >
                      </v-flex>
                    </v-layout>
                    <template v-if="vBillingContact?.id">
                      <p class="mb-0">{{ vBillingContact.full_name }}</p>
                      <p class="mb-0">{{ vBillingContact.primary_phone }}</p>
                      <p class="mb-0">{{ vBillingContact.primary_email }}</p>
                    </template>
                    <template v-else-if="vCustomer?.id">
                      <p class="mb-0 red--text">Please select contact person</p>
                    </template>
                    <template v-else>
                      <p class="mb-0 red--text">Please select customer</p>
                    </template>
                    <v-layout v-if="false" class="mt-3">
                      <v-flex md4
                        ><label class="my-0">Email Notification </label></v-flex
                      >
                      <v-flex md8>
                        <v-switch
                          v-model="dbQuotation.notify_billing_contact_person"
                          class="m-0 p-0"
                          color="cyan"
                          dense
                          inset
                        />
                      </v-flex>
                    </v-layout>
                  </v-col>
                </v-row>
              </v-col>
              <v-col md="5">
                <table width="100%" style="table-layout: fixed">
                  <!-- <tr>
                      <td width="150" class="font-weight-600">
                        <label>Rate opportunity</label>
                      </td>
                      <td class="font-weight-700">
                        <v-rating
                          :readonly="formLoading"
                          v-model.trim="dbQuotation.rating"
                          background-color="orange lighten-3"
                          color="orange"
                        ></v-rating>
                      </td>
                    </tr> -->

                  <tr v-if="false">
                    <td width="150" class="font-weight-600">
                      <label for="quotation-title" class="">Opportunity</label>
                    </td>
                    <td>
                      <v-autocomplete
                        dense
                        color="cyan"
                        filled
                        id="select-Opportunity"
                        :items="opportunityList"
                        :disabled="formLoading"
                        :loading="formLoading"
                        v-model.trim="dbQuotation.opportunity"
                        placeholder="Select Opportunity"
                        solo
                        flat
                        item-color="cyan"
                        item-text="opportunity_name"
                        item-value="id"
                        hide-details
                      >
                        <template v-slot:no-data>
                          <v-list-item>
                            <v-list-item-content>
                              <v-list-item-title
                                v-html="'No Opportunity Found.'"
                              ></v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </template>
                      </v-autocomplete>
                    </td>
                  </tr>
                  <tr>
                    <td width="150" class="font-weight-600">
                      <label for="quotation-title" class=""
                        ><!-- Order Con. -->
                        Order #</label
                      >
                    </td>
                    <td class="font-weight-700 font-size-21">
                      <v-chip
                        label
                        color="chip-custom-blue"
                        outlined
                        class="text-white p-3 mr-4"
                        style="font-size: 21px !important; font-weight: 700"
                      >
                        <template>
                          {{ updatedQuotationData.barcode }}
                        </template>
                      </v-chip>
                    </td>
                  </tr>
                  <tr>
                    <td width="150" class="font-weight-600">
                      <label for="quotation-title" class=""
                        ><!-- Order Con. -->
                        OC Total Amount</label
                      >
                    </td>
                    <td class="font-weight-700 font-size-21">
                      {{ formatMoney(dbQuotation.grand_total) }}
                    </td>
                  </tr>
                  <tr>
                    <td width="150" class="font-weight-600">
                      <label for="quotation-title" class="">Date</label>
                    </td>
                    <td>
                      <DatePicker solo :default-date=" dbQuotation.date "
                      v-model="dbQuotation.date" mandatory: true />
                    </td>
                  </tr>
                  <tr>
                    <td width="150" class="font-weight-600">
                      <label for="your-reference-no">Payment mode</label>
                    </td>
                    <td>
                      <v-select
                        id="payment-mode"
                        :items="paymentModeList"
                        dense
                        filled
                        solo
                        flat
                        hide-details
                        :disabled="formLoading"
                        v-model.trim="dbQuotation.payment_mode"
                        label="Payment mode"
                        color="cyan"
                        item-text="text"
                        item-value="text"
                        item-color="cyan"
                      ></v-select>
                    </td>
                  </tr>
                  <tr>
                    <td width="150" class="font-weight-600">
                      <label for="your-reference-no">Transaction ID</label>
                    </td>
                    <td>
                      <v-text-field
                        id="transaction-id"
                        dense
                        color="cyan"
                        filled
                        :disabled="formLoading"
                        v-model.trim="dbQuotation.transaction_id"
                        label="Transaction ID"
                        solo
                        flat
                        hide-details
                      ></v-text-field>
                    </td>
                  </tr>
                  <tr v-if="false">
                    <td width="150" class="font-weight-600">
                      <label for="sales-representatives"
                        >Sales representatives</label
                      >
                    </td>
                    <td>
                      <v-autocomplete
                        dense
                        color="cyan"
                        filled
                        id="sales-representatives"
                        :items="userList"
                        :disabled="formLoading"
                        :loading="formLoading"
                        v-model.trim="dbQuotation.sales_executive"
                        placeholder="Sales representatives"
                        solo
                        flat
                        item-color="cyan"
                        item-text="display_name"
                        item-value="id"
                        hide-details
                      >
                        <template v-slot:no-data>
                          <v-list-item>
                            <v-list-item-content>
                              <v-list-item-title
                                v-html="'No Sales Representative Found.'"
                              ></v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </template>
                      </v-autocomplete>
                    </td>
                  </tr>
                  <tr v-if="false">
                    <td width="150" class="font-weight-600">
                      <label for="prepared-by" class="required"
                        >Prepared by</label
                      >
                    </td>
                    <td>
                      <v-autocomplete
                        readonly
                        dense
                        color="cyan"
                        filled
                        id="prepared-by"
                        :items="userList"
                        :disabled="formLoading"
                        :loading="formLoading"
                        :rules="[
                          validateRules.required(
                            dbQuotation.prepared_by_id,
                            'Prepared by'
                          ),
                        ]"
                        v-model.trim="dbQuotation.prepared_by_id"
                        placeholder="Prepared by"
                        solo
                        flat
                        item-color="cyan"
                        item-text="display_name"
                        item-value="id"
                        hide-details
                      >
                        <template v-slot:no-data>
                          <v-list-item>
                            <v-list-item-content>
                              <v-list-item-title
                                v-html="'No User Found.'"
                              ></v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </template>
                      </v-autocomplete>
                    </td>
                  </tr>
                  <tr v-if="$route?.query?.type == 'initial'">
                    <td width="150" class="font-weight-600">
                      <label for="your-reference-no" class="required"
                        >Down Payment</label
                      >
                    </td>
                    <td>
                      <v-text-field
                        dense
                        color="cyan"
                        filled
                        :disabled="formLoading"
                        :loading="formLoading"
                        append-icon="mdi-percent"
                        type="number"
                        @keypress="setInitialPayment"
                        @keyup="checkPercentage"
                        v-model="dbQuotation.initial_payment_in_percentage"
                        v-model.trim.number.lazy="
                          dbQuotation.initial_payment_in_percentage
                        "
                        label="Down Payment"
                        :rules="[
                          validateRules.required(
                            dbQuotation.initial_payment_in_percentage,
                            'Down Payment'
                          ),
                          validateRules.minLength(
                            dbQuotation.initial_payment_in_percentage,
                            'Down Payment',
                            1
                          ),
                          validateRules.maxLength(
                            dbQuotation.initial_payment_in_percentage,
                            'Down Payment',
                            100
                          ),
                        ]"
                        solo
                        flat
                        hide-details
                      ></v-text-field>
                    </td>
                  </tr>
                  <tr v-if="false">
                    <td width="150" class="font-weight-600">
                      <label for="prepared-by" class="required"
                        >Validity
                      </label>
                    </td>
                    <td>
                      <!--  <pre>{{ validPeriod }}</pre> -->
                      <v-autocomplete
                        dense
                        color="cyan"
                        filled
                        id="prepared-by"
                        :items="validPeriod"
                        :rules="[
                          validateRules.required(
                            dbQuotation.validity_till,
                            'Validity'
                          ),
                        ]"
                        :disabled="formLoading"
                        :loading="formLoading"
                        v-model.trim="dbQuotation.validity_till"
                        placeholder="validity"
                        solo
                        flat
                        item-color="cyan"
                        item-text="text"
                        item-value="value"
                        hide-details
                      >
                        <template v-slot:no-data>
                          <v-list-item>
                            <v-list-item-content>
                              <v-list-item-title
                                v-html="'No User Found.'"
                              ></v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </template>
                      </v-autocomplete>
                    </td>
                  </tr>
                  <tr v-if="false">
                    <td width="150" class="font-weight-600">
                      <label for="tags">Tags </label>
                    </td>
                    <td>
                      <tag-auto-complete
                        v-if="related_type"
                        :type="tag_type"
                        v-model="dbQuotation.tags"
                        placeholder="tags"
                      />
                    </td>
                  </tr>

                  <tr v-if="false">
                    <td width="150" class="font-weight-600">
                      <label>Is Currency ?</label>
                    </td>
                    <td valign="middle">
                      <v-autocomplete
                        hide-details
                        v-model="dbQuotation.selling_currency"
                        :items="currencyListType"
                        dense
                        flat
                        filled
                        item-color="cyan"
                        color="cyan"
                        solo
                        item-value="value"
                        item-text="text"
                      >
                      </v-autocomplete>
                    </td>
                  </tr>
                </table>
              </v-col>

              <v-col cols="12" ref="qtLineItem">
                <v-container fluid>
                  <v-card flat class="custom-grey-border remove-border-radius">
                    <v-card-title
                      class="headline grey lighten-4"
                      @click="show_line_items = !show_line_items"
                    >
                      <h3
                        class="font-weight-700 custom-headline color-custom-blue"
                      >
                        <v-icon>{{
                          !show_line_items
                            ? "mdi-chevron-down"
                            : "mdi-chevron-up"
                        }}</v-icon>
                        Line Items
                      </h3>
                    </v-card-title>
                    <v-expand-transition>
                      <v-card-text
                        v-show="show_line_items"
                        ref="lineItem"
                        class="font-size-16 py-0"
                      >
                        <line-item
                          :item-set="setIteam"
                          :related-type="8"
                          :related-detail="updatedQuotationData"
                          :key="`quotation-line-item-${uniqueId}`"
                        />
                      </v-card-text>
                    </v-expand-transition>
                  </v-card>
                </v-container>
              </v-col>

              <v-col cols="12" ref="qtNote" v-if="false">
                <v-container fluid>
                  <v-card flat class="custom-grey-border remove-border-radius">
                    <v-card-title
                      class="headline grey lighten-4"
                      @click="show_notes_attachment = !show_notes_attachment"
                    >
                      <h3
                        class="font-weight-700 custom-headline color-custom-blue"
                      >
                        <v-icon>{{
                          !show_notes_attachment
                            ? "mdi-chevron-down"
                            : "mdi-chevron-up"
                        }}</v-icon>
                        INTERNAL NOTES
                      </h3>
                    </v-card-title>
                    <v-expand-transition>
                      <v-card-text
                        class="p-6 font-size-16"
                        v-show="show_notes_attachment"
                      >
                        <v-layout>
                          <v-flex class="mr-4" md6>
                            <label class="font-size-16 font-weight-600"
                              >Internal Note</label
                            >
                            <!--  <v-autocomplete
                                dense
                                color="cyan"
                                filled
                                label="Internal Note"
                                solo
                                flat
                                class="my-2"
                                item-color="cyan"
                                item-text="label"
                                item-value="id"
                                hide-details
                                :disabled="formLoading"
                                :loading="formLoading"
                                :items="adminNoteList"
                                v-model="dbQuotation.admin_note_id"
                                v-on:change="update_admin_note()"
                              >
                              </v-autocomplete> -->
                            <tiny-mce
                              :disabled="formLoading"
                              v-model="dbQuotation.admin_note"
                            />
                          </v-flex>
                          <v-flex md6>
                            <label class="font-size-16 font-weight-600"
                              >Client Note
                              <!-- <span class="text--secondary font-weight-500"
                                    >(visible on PDF)</span
                                  > --></label
                            >
                            <!--  <v-autocomplete
                                  dense
                                  color="cyan"
                                  filled
                                  label="Client Note"
                                  solo
                                  flat
                                  class="my-2"
                                  item-color="cyan"
                                  item-text="label"
                                  item-value="id"
                                  hide-details
                                  :disabled="formLoading"
                                  :loading="formLoading"
                                  :items="clientNoteList"
                                  v-model="dbQuotation.client_note_id"
                                  v-on:change="update_client_note()"
                                >
                                </v-autocomplete> -->
                            <tiny-mce
                              :disabled="formLoading"
                              v-model="dbQuotation.client_note"
                            />
                          </v-flex>
                        </v-layout>
                        <v-layout class="mt-4">
                          <v-flex md12 class="mr-4" v-if="false">
                            <label class="font-size-16 font-weight-600"
                              >Attachments</label
                            >
                            <file-upload
                              v-model="dbQuotation.attachments"
                              :maxLimit="5"
                            >
                            </file-upload>
                          </v-flex>
                          <v-flex md12>
                            <DocumnetList
                              v-model="dbQuotation.attachments"
                              :document-type="2"
                            >
                            </DocumnetList>
                          </v-flex>
                        </v-layout>
                      </v-card-text>
                    </v-expand-transition>
                  </v-card>
                </v-container>
              </v-col>
              <v-col
                cols="12"
                class="pb-15"
                ref="qtTermsConditions"
                v-if="false"
              >
                <v-container fluid>
                  <v-card flat class="custom-grey-border remove-border-radius">
                    <v-card-title
                      class="headline grey lighten-4"
                      @click="show_terms_condition = !show_terms_condition"
                    >
                      <h3
                        class="font-weight-700 custom-headline color-custom-blue"
                      >
                        <v-icon>{{
                          !show_terms_condition
                            ? "mdi-chevron-down"
                            : "mdi-chevron-up"
                        }}</v-icon>
                        TERMS &amp; CONDITIONS
                      </h3>
                    </v-card-title>
                    <v-expand-transition>
                      <v-card-text
                        class="p-6 font-size-16"
                        v-show="show_terms_condition"
                      >
                        <v-autocomplete
                          dense
                          color="cyan"
                          filled
                          label="Terms &amp; Conditions"
                          solo
                          flat
                          class="mb-2"
                          item-color="cyan"
                          item-text="label"
                          item-value="id"
                          hide-details
                          :disabled="formLoading"
                          :loading="formLoading"
                          :items="termsConditionList"
                          v-model="dbQuotation.term_condition_id"
                          v-on:change="update_term_condition()"
                        />
                        <tiny-mce
                          :disabled="formLoading"
                          v-model="dbQuotation.term_conditions"
                        />
                      </v-card-text>
                    </v-expand-transition>
                  </v-card>
                </v-container>
              </v-col>
            </v-row>
          </v-form>

          <customer-select
            :dialog="reCustomerDialog"
            @close="closeCustomerDialog"
          />
          <customer-create :key="`quotation-customer-create-${dialog_key}`" />
          <person-select
            :dialog="contactDialog"
            v-on:close="contactDialog = false"
            :type="contactType"
          />
          <address-select
            :dialog="propertyDialog"
            v-on:close="propertyDialog = false"
            :type="propertyType"
            :label="propertyLabel"
          />
          <Dialog :common-dialog="confirmDialog">
            <template v-slot:title>Confirmation</template>
            <template v-slot:body>
              <v-container fluid class="py-0">
                <div
                  class="p-4 mb-4 font-size-17 font-weight-400 warning-background"
                >
                  <p
                    class="mb-0 d-flex align-center font-weight-600 red--text text--darken-3"
                  >
                    <v-icon color="red darken-3" class="mr-2"
                      >mdi-alert-circle-outline</v-icon
                    >
                    Are you sure you want to create this proforma without
                    applying discount in SG $?
                  </p>
                </div>
              </v-container>
            </template>
            <template v-slot:action>
              <v-btn
                class="mx-2 custom-grey-border custom-bold-button white--text"
                color="cyan"
                :disabled="formLoading"
                v-on:click="
                  changeConfirmed = true;
                  onSubmit();
                "
              >
                Continue
              </v-btn>
              <v-btn
                :disabled="formLoading"
                class="mx-2 custom-grey-border custom-bold-button"
                v-on:click="confirmDialog = false"
              >
                Close
              </v-btn>
            </template>
          </Dialog>
        </div>
      </perfect-scrollbar>
    </template>
  </CreateUpdateTemplate>
</template>
  
  <script>
import CreateUpdateTemplate from "@/view/pages/partials/Create-Update-Template.vue";
import { PreventDialogEventBus } from "@/core/lib/prevent.reload.lib";
/* import Dialog from "@/view/pages/partials/Dialog"; */
import { mapGetters } from "vuex";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ValidationMixin from "@/core/plugins/validation-mixin";
import DatePicker from "@/view/pages/partials/Datepicker.vue";
import {
  /* SET_LINE_ITEM, */
  /*  SET_EDIT_CALCULATION, */
  CLEAR_LINE_ITEM,
} from "@/core/services/store/line.item.module";
import TinyMCE from "@/view/pages/partials/TinyMCE.vue";
import moment from "moment-timezone";
import ObjectPath from "object-path";
import {
  SET_DIALOG_STATUS,
  SET_CREATE_DIALOG_STATUS,
  CLEAR_CUSTOMER,
} from "@/core/services/store/customer.module";
import {
  SET_CUSTOMER,
  SET_PROPERTY,
  SET_PROPERTY_CONTACT,
  SET_BILLING,
  SET_BILLING_CONTACT,
  RESET_CREATE_STATE,
  SET_VISIT_LINE_ITEM,
  SET_INITIAL_PAYMENT,
  /* SET_VISIT_CALCULATION, */
} from "@/core/services/store/visit.module";
import {
  UPLOAD,
  QUERY,
  POST,
  PATCH,
} from "@/core/services/store/request.module";
import CustomerSelect from "@/view/components/CustomerSelect.vue";
import CustomerCreate from "@/view/components/CustomerCreateDialog.vue";
import PersonSelect from "@/view/components/PersonSelect.vue";
import AddressSelect from "@/view/components/AddressSelect.vue";
import { ErrorEventBus, InitializeError } from "@/core/lib/message.lib";
import KTCookie from "@/assets/js/components/cookie";
import DocumnetList from "@/view/pages/DocumnetList";
import TagAutoComplete from "@/view/components/TagAutoCompleteInput.vue";
import TextAreaField from "@/view/components/app-component/TextAreaField.vue";
import CommonMixin from "@/core/plugins/common-mixin";
import LineItem from "@/view/pages/partials/Line-Item-V2.vue";
import Dialog from "@/view/pages/partials/Dialog";

moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);

export default {
  name: "create-quotation",
  mixins: [ValidationMixin, CommonMixin],
  data() {
    return {
      schedule_key: Number(new Date()),
      dialog_key: Number(new Date()),
      uniqueId: Number(new Date()),
      confirmDialog: false,
      changeConfirmed: false,
      formLoading: false,
      formValid: true,
      quotationCreated: false,
      show_line_items: true,
      show_notes_attachment: false,
      show_terms_condition: false,
      related_type: 0,
      tag_type: null,
      contractDialog: false,
      quotation_Id: 0,
      quotation_recurring_invoice: false,
      is_quotation_invoice_allow: false,
      durationTypeList: [
        { text: "Year", value: "year" },
        { text: "Month", value: "month" },
      ],
      baseList: [
        { text: "No. of Services", value: "service_no" },
        { text: "Hours", value: "hours" },
        { text: "None", value: "none" },
      ],
      paymentDueList: [
        { value: 2, text: "Net 15" },
        { value: 3, text: "Net 30" },
        { value: 4, text: "Net 45" },
      ],
      neededInvoiceList: [
        { value: 0, text: "No" },
        { value: 1, text: "Yes" },
      ],
      invoiceTypes: [
        { value: "one-off", text: "One Off" },
        { value: "recurring", text: "Recurring" },
      ],
      contractType: [
        {
          text: "Yes",
          value: 1,
        },
        {
          text: "No",
          value: 0,
        },
      ],
      currencyListType: ["SGD", "USD"],
      validPeriod: [
        { value: 15, text: "15 Days" },
        { value: 30, text: "30 Days" },
        { value: 60, text: "60 Days" },
        { value: 90, text: "90 Days" },
      ],
      show_invoicing: false,
      paymentModeList: [],
      dbQuotation: {
        barcode: null,
        title: null,
        description: null,
        billing_contact_person: false,
        property_contact_person: false,
        rating: 1,
        type: 1,
        tags: [],
        date: null,
        prepared_by_id: null,
        sales_executive: null,
        opportunity: null,
        reference: null,
        open_till: null,
        validity_till: null,
        selling_currency: "SGD",
        initial_payment_in_percentage: 50,
        payment_mode: null,
        transaction_id: null,
        /*   sale_by: null, */
        /*     customer_ref: null, */
        your_reference: null,
        /*   quote_by: null, */
        term_condition_id: 0,
        term_conditions: null,
        /*   admin_note_id: 0, */
        admin_note: null,
        /*  client_note_id: 0, */
        client_note: null,
        attachments: [
          {
            file: null,
            name: null,
            remark: null,
            suffix: null,
            tags: null,
            search: null,
            start_date: null,
            end_date: null,
            reminder_date: null,
          },
        ],
        is_contract: 0,
        is_currency: "SGD",
        duration_value: 1,
        duration_type: "year",
        contract_start: moment().format("YYYY-MM-DD"),
        contract_end: moment().add(1, "year").format("YYYY-MM-DD"),
        duration: null,
        total_service: null,
        base_type: "service_no",
      },
      setIteam: false,

      generateTypeList: [
        {
          value: "auto_create_on_acceptance",
          text: "Auto create on quote acceptance",
        },
        {
          value: "auto_create_on_send_acceptance",
          text: "Auto create and send on quote acceptance",
        },
      ],
      amountTypeList: [
        {
          value: "total_amount",
          text: "Generate all invoices for the quote amount.",
        },
        {
          value: "each_amount",
          text: "Generate all invoices for (1/No. of Schedule) the quote amount",
        },
      ],
      attachments: [
        {
          accepted_file: null,
          file_name: null,
          file_type: null,
        },
      ],
      adminNoteList: [],
      clientNoteList: [],
      termsConditionList: [],
      userList: [],
      opportunityList: [],
      dbLineItems: [],
      dbEquipments: [],
      todayDate: null,
      updatedQuotationData: {},
      propertyType: null,
      propertyLabel: null,
      propertyDialog: false,
      contactType: null,
      contactDialog: false,
      contract_status: false,
    };
  },
  components: {
    "customer-select": CustomerSelect,
    "customer-create": CustomerCreate,
    "person-select": PersonSelect,
    "address-select": AddressSelect,
    "line-item": LineItem,
    DatePicker,
    "tiny-mce": TinyMCE,
    // "file-upload": FileUpload,
    "tag-auto-complete": TagAutoComplete,
    TextAreaField,
    Dialog,
    CreateUpdateTemplate,
    DocumnetList,
  },
  methods: {
    setInitialPayment() {
      this.$store.commit(
        SET_INITIAL_PAYMENT,
        this.dbQuotation.initial_payment_in_percentage
      );
    },
    checkPercentage() {
      if (this.dbQuotation.initial_payment_in_percentage > 100) {
        this.dbQuotation.initial_payment_in_percentage = 0;
        this.$store.commit(SET_INITIAL_PAYMENT, 0);
        ErrorEventBus.$emit(
          "update:error",
          InitializeError("Percentage Can not be greater then 100.")
        );
        return false;
      }
      this.$store.commit(
        SET_INITIAL_PAYMENT,
        this.dbQuotation.initial_payment_in_percentage
      );
    },
    createCustomerDialog() {
      this.dialog_key = Number(new Date());
      this.$nextTick(() => {
        this.$store.commit(SET_CREATE_DIALOG_STATUS, true);
      });
    },
    remove_row(target, index) {
      ObjectPath.del(this, `${target}.${index}`);
      this.$nextTick(() => {
        if (!this.attachments.length) {
          this.add_attachment_row();
        }
      });
    },
    add_attachment_row() {
      this.attachments.push({
        accepted_file: null,
        file_name: null,
        file_type: null,
      });
    },
    get_file_extension(file_type) {
      if (file_type) {
        return `.${file_type}`;
      }
      return null;
    },
    update_file_name(index) {
      const file = this.attachments[index].accepted_file;
      if (file) {
        this.attachments[index].file_name = file.name
          .split(".")
          .slice(0, -1)
          .join(".");
        this.attachments[index].file_type = file.name.split(".").pop();
        this.add_attachment_row();
      }
    },
    update_term_condition() {
      let output = this.lodash.find(this.termsConditionList, {
        id: this.dbQuotation.term_condition_id,
      });
      if (output) {
        this.dbQuotation.term_conditions = output.description;
      }
    },
    update_admin_note() {
      let output = this.lodash.find(this.adminNoteList, {
        id: this.dbQuotation.admin_note_id,
      });
      if (output) {
        this.dbQuotation.admin_note = output.description;
      }
    },
    update_client_note() {
      let output = this.lodash.find(this.clientNoteList, {
        id: this.dbQuotation.client_note_id,
      });
      if (output) {
        this.dbQuotation.client_note = output.description;
      }
    },
    uploadFiles() {
      let _this = this;
      return new Promise((resolve, reject) => {
        let formData = new FormData();

        let status = false;

        for (let i = 0; i < _this.attachments.length; i++) {
          if (_this.attachments[i].accepted_file) {
            status = true;
            formData.append(
              `files[${i}]`,
              _this.attachments[i].accepted_file,
              _this.attachments[i].file_name
            );
          }
        }

        if (!status) {
          resolve([]);
        } else {
          _this.$store
            .dispatch(UPLOAD, { url: "file-manager/upload", data: formData })
            .then(({ data }) => {
              resolve(data);
            })
            .catch((error) => {
              reject(error);
            });
        }
      });
    },
    closeCustomerDialog() {
      this.$store.commit(SET_DIALOG_STATUS, false);
    },
    openDialog(type) {
      this.$store.commit(SET_DIALOG_STATUS, false);
      this.propertyType = null;
      this.propertyLabel = null;
      this.propertyDialog = false;
      this.contactType = null;
      this.contactDialog = false;

      if (type == "customer") {
        this.$store.commit(SET_DIALOG_STATUS, true);
      } else {
        if (!this.vCustomerId) {
          ErrorEventBus.$emit("update:error", "Please Select Customer.");
          return false;
        }
        if (type == "billing") {
          this.propertyType = "billing";
          this.propertyLabel = "Billing Location";
          this.propertyDialog = true;
        }
        if (type == "billing-contact") {
          this.contactType = "billing";
          this.contactDialog = true;
        }
        if (type == "property") {
          this.propertyType = "property";
          this.propertyLabel = "Service Location";
          this.propertyDialog = true;
        }
        if (type == "property-contact") {
          this.contactType = "property";
          this.contactDialog = true;
        }
      }
    },
    validateJob() {
      if (!this.vCustomerId) {
        ErrorEventBus.$emit("update:error", "Select Customer");
        return false;
      }

      if (!this.vPropertyId) {
        ErrorEventBus.$emit("update:error", "Select Service Location");
        return false;
      }

      if (!this.vPropertyContactId) {
        ErrorEventBus.$emit("update:error", "Select Service Contact Person");
        return false;
      }

      if (!this.vBillingId) {
        ErrorEventBus.$emit("update:error", "Select Billing Location");
        return false;
      }

      if (!this.vBillingContactId) {
        ErrorEventBus.$emit("update:error", "Select Billing Contact Person");
        return false;
      }
      /*  const allHeaders = this.vSelectedLineItem.every(
          (obj) => obj.type === "header"
        ); */
      if (this.v2SelectedLineItem?.length == 0) {
        ErrorEventBus.$emit("update:error", "Select Line Item");
        return false;
      }

      const validateStatus = this.$refs.createQuotationForm.validate();

      const formErrors = this.validateForm(this.$refs.createQuotationForm);

      if (formErrors.length) {
        for (let i = 0; i < formErrors.length; i++) {
          ErrorEventBus.$emit("update:error", InitializeError(formErrors[i]));
        }
        return false;
      }

      if (validateStatus) {
        if (
          this.$route?.query?.type == "deliverable" &&
          !this.vCalculations?.consume_discount &&
          !this.changeConfirmed &&
          this.vCalculations?.discount_type == 1 &&
          this.vCalculations?.discount_value > 0
        ) {
          this.confirmDialog = true;
        } else if (
          this.$route?.query?.type == "deliverable" &&
          !this.vCalculations?.consume_lump_discount &&
          !this.changeConfirmed &&
          this.vCalculations?.lump_discount_type == 1 &&
          this.vCalculations?.lump_discount_value > 0
        ) {
          this.confirmDialog = true;
        } else {
          this.onSubmit();
        }
      }
    },
    async onSubmit() {
      this.formLoading = true;

      const new_attachments = await this.uploadFiles();

      const request = {
        ...this.dbQuotation,

        attachments: [...this.dbQuotation.attachments, ...new_attachments],
        customer: this.vCustomerId,
        property: this.vPropertyId,
        billing: this.vBillingId,
        property_contact_person: this.vPropertyContactId,
        billing_contact_person: this.vBillingContactId,
        proforma_type: this.$route?.query?.type ?? null,
        orderId: this.lodash.toSafeInteger(this.$route?.query?.orderId) ?? 0,
        calculation: this.vCalculations,
        "line-item": this.vSelectedLineItem,
      };

      let requestType = POST;
      let requestURL = "performa-v1";

      let quotationId = this.$route?.query?.edit ?? 0;

      if (quotationId) {
        requestType = PATCH;
        requestURL = `performa-v1/${quotationId}`;
      }

      this.$store
        .dispatch(requestType, { url: requestURL, data: request })
        .then(() => {
          this.quotationCreated = true;
          this.$store.dispatch(CLEAR_CUSTOMER);
          this.$store.dispatch(CLEAR_LINE_ITEM);
          this.$nextTick(() => {
            this.$router.push(
              this.getDefaultRoute("orders.detail", {
                params: { id: this.$route?.query?.orderId },
              })
            );
          });
        })
        .catch(() => {
          this.formLoading = false;
        });
    },
    get_attributes() {
      this.$store
        .dispatch(QUERY, {
          url: "order/options",
          data: {
            order: ObjectPath.get(this.$route, "query.orderId"),
            type: ObjectPath.get(this.$route, "query.type"),
          },
        })
        .then((response) => {
          this.dbQuotation.barcode = ObjectPath.get(response, "data.barcode");

          this.$store.dispatch(SET_BREADCRUMB, [
            { title: "Proforma", route: "proforma" },
            { title: "Create" },
            { title: this.dbQuotation.barcode },
          ]);

          this.userList = ObjectPath.get(response, "data.sales_users", []);
          this.paymentModeList = ObjectPath.get(
            response,
            "data.payment_modes",
            []
          );
          this.dbQuotation.sale_by = ObjectPath.get(
            response,
            "data.user_id",
            0
          );
          this.dbQuotation.quote_by = ObjectPath.get(
            response,
            "data.user_id",
            0
          );

          let dbQuotation = ObjectPath.get(response, "data.order");
          this.updatedQuotationData = ObjectPath.get(response, "data.order");
          if (dbQuotation?.id) {
            this.dbQuotation.description = dbQuotation?.description;
            this.dbQuotation.your_reference = dbQuotation?.your_reference;
            this.dbQuotation.reference = dbQuotation?.reference;
            this.dbQuotation.prepared_by_id = this.lodash.toSafeInteger(
              dbQuotation?.prepared_by_id
            );
            this.dbQuotation.sales_executive = dbQuotation?.sales_executive;
            this.dbQuotation.open_till = dbQuotation?.open_till;
            this.dbQuotation.validity_till = dbQuotation?.validity_till;
            this.dbQuotation.type = dbQuotation?.type;
            this.dbQuotation.grand_total = dbQuotation?.grand_total;

            this.dbQuotation.date = moment(dbQuotation?.date).format(
              "YYYY-MM-DD"
            );

            this.$store.commit(SET_CUSTOMER, dbQuotation?.customer_relation);
            this.$store.commit(SET_PROPERTY, dbQuotation?.property_relation);
            this.$store.commit(
              SET_PROPERTY_CONTACT,
              dbQuotation?.property_contact_person_relation
            );
            this.$store.commit(SET_BILLING, dbQuotation?.billing_relation);
            this.$store.commit(
              SET_BILLING_CONTACT,
              dbQuotation?.billing_contact_person_relation
            );
            if (response?.data?.lineItem?.length > 0) {
              this.$store.commit(
                SET_VISIT_LINE_ITEM,
                response?.data?.lineItem ?? []
              );
              this.setIteam = true;

              this.$store.commit(
                SET_INITIAL_PAYMENT,
                this.dbQuotation.initial_payment_in_percentage
              );
            }
            /*    this.$store.dispatch(SET_VISIT_CALCULATION, {
              discount_value: dbQuotation.total_discount,
            }); */

            /* 
            this.$store.dispatch(SET_EDIT_CALCULATION, {
              total_selling: response?.data?.quotation?.total_selling,
              total_cost_value: response?.data?.quotation?.total_cost_value,
              total_margin: response?.data?.quotation?.total_margin,
              discount_value: response?.data?.quotation?.discount_value,
              discount_type: response?.data?.quotation?.discount_type,
              main_discount: response?.data?.quotation?.main_discount,
              lump_discount_value:
                response?.data?.quotation?.lump_discount_value,
              lump_discount_type: response?.data?.quotation?.lump_discount_type,
              lump_sum_discount: response?.data?.quotation?.lump_sum_discount,
              discounted_total_selling:
                response?.data?.quotation?.discounted_total_selling,
              grand_total: response?.data?.quotation?.grand_total,
            }); */
          }
        })
        .catch((error) => {
          this.logError(error);
          this.goBack();
        })
        .finally(() => {
          // this.formLoading = false;
        });
    },
  },
  mounted() {
    this.$refs.createQuotationForm.validate();
    this.get_attributes();
  },
  destroyed() {
    this.$store.dispatch(CLEAR_CUSTOMER);
    this.$store.dispatch(RESET_CREATE_STATE);
    this.$store.dispatch(CLEAR_LINE_ITEM);
  },
  beforeDestroy() {
    KTCookie.setCookie("kt_aside_toggle_state", "off");
    localStorage.setItem("kt_aside_toggle_state", "off");
  },
  created() {
    this.todayDate = moment().format("YYYY-MM-DD");
    this.dbQuotation.date = this.todayDate;
    this.dbQuotation.invoice_date = this.todayDate;
    this.dbQuotation.enquiry = this.lodash.toSafeInteger(
      ObjectPath.get(this.$route, "query.enquiry")
    );
  },
  beforeCreate() {
    KTCookie.setCookie("kt_aside_toggle_state", "on");
    localStorage.setItem("kt_aside_toggle_state", "on");
    this.$store.dispatch(CLEAR_CUSTOMER);
    this.$store.dispatch(RESET_CREATE_STATE);
    this.$store.dispatch(CLEAR_LINE_ITEM);
  },
  computed: {
    ...mapGetters([
      "vCustomer",
      "vProperty",
      "vPropertyContact",
      "vBilling",
      "vBillingContact",
      "vCustomerId",
      "vPropertyId",
      "vPropertyContactId",
      "vBillingId",
      "vBillingContactId",
      "reCustomerDialog",
      "vSelectedLineItem",
      "vCalculations",
      "recurringInvoiceScheduleTypeList",
      "invoiceRecurringSchedule",
      "invoiceScheduleOutput",
      "customError",
      "quoteInvoiceParameters",
      "invoiceRawScheduleOutput",
      "dbInvoiceUpdateSchedule",
      "customErrorOcc",
      "vSelectedLineItem",
    ]),
  },
  beforeRouteLeave(to, from, next) {
    const _this = this;
    if (_this.quotationCreated) {
      next();
    } else {
      _this.routePreventDialog = true;
      PreventDialogEventBus.$on("stay-on-page", function () {
        _this.routePreventDialog = false;
        next(false);
      });
      PreventDialogEventBus.$on("leave-page", function () {
        _this.routePreventDialog = false;
        next();
      });
    }
  },
};
</script>
  
  <style lang="scss" scoped>
.quotation-create-page {
  border-top: solid 8px #f7941e;

  label {
    padding-left: 5px;
    font-weight: 600;
  }

  p {
    padding-left: 5px;
  }
}

.form-action {
  display: flex;
  justify-content: space-between;

  .form-title {
    font-size: 24px;
    font-family: "Poppins", Helvetica, Arial, sans-serif;
    font-weight: 700;
    color: #24326d;
    letter-spacing: 1.5px;
    line-height: 1.11em;

    .form-title-link {
      color: #4d6974;
      border-bottom: dashed 2px #4d6974;
    }
  }
}
</style>
  